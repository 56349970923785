import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import { DaysOfWeek } from "@udok/lib/internal/constants";
import fileDownload from "js-file-download";
import {
  Schedule,
  Appointment,
  AppointmentStatus,
  Location,
  Clinic,
  ResourceType,
  DocumentData,
  FileAttachments,
  AppointmentCollectDocument,
  AppointmentCollectDocumentPresentation,
  FileData,
  AppointmentReschedule,
  FilterAppointmentReschedule,
  NewAppointment,
  Healthplan,
  InvoiceView,
  AppointmentStatusData,
  AppointmentReturnPending,
  FilterAppointment,
  ScheduleFilter,
  FilterHealthPlan,
  PatientHealthplanCard,
  FilterPatientHealthplanCard,
  AppointmentCommunication,
  AppointmentDisplaySettings,
  AppointmentStatusResponse,
  FilterAttachment,
  ImmediateCareAppointmentForm,
  AppointmentNotificationOptions,
  AppointmentSettingsInfo,
  NewAppointmentStatus,
} from "@udok/lib/api/models";
import { emitAppointmentCreated } from "@udok/lib/analytics";
import {
  fetchSchedules,
  fetchSchedule,
  updateSchedule,
  deleteSchedule,
  createSchedule,
  fetchAppointments,
  fetchAppointment,
  sendEditStatusAppointment,
  fetchLocation,
  sendEditAppointment,
  fetchAttachmentsByappoID,
  NewCollectDocumentForm,
  deleteCollectDocument,
  createCollectDocument,
  NewDocumentForm,
  fetchAppoCollectDocuments,
  FormData,
  createFormData,
  fetchAppointmentReschedule,
  fetchAppointmentReschedules,
  updateAppointmentReschedule,
  createAppointment,
  createAppointmentAttachment,
  fetchHealthplans,
  fetchAppointmentStatus,
  fetchAppointsReturnPending,
  sendControlledAppointmentPhone,
  fetchAppointmentCommunication,
  getAppointmentDisplaySettings,
  createImmediateCareAppointment,
  getAppointmentSettings,
} from "@udok/lib/api/schedule";
import {
  createHealthplanCard,
  updateHealthplanCard,
  fetchHealthplanCards,
  deleteHealthplanCard,
} from "@udok/lib/api/patient";
import {
  fetchClinicProfile,
  fetchClinicProfileByClinID,
} from "@udok/lib/api/clinic";
import { fetchInvoicesByAppoID } from "@udok/lib/api/billing";
import {
  uploadFile,
  deleteFile,
  fetchFileMetadata,
  fetchFile,
} from "@udok/lib/api/files";
import { actions as docAction, selectTemplateByID } from "ducks/documents";
import { getUserMe } from "ducks/user";
import { fetchDocument, fetchDocumentTemplate } from "@udok/lib/api/document";
import { fetchCollectDocument } from "@udok/lib/api/collectDocument";
import { getToken, UNAUTHORIZED } from "./auth";
import { actions as locationActions, locationByLocaID } from "ducks/location";
import {
  invoicesByIDSelector,
  refundInvoicePayment,
  actions as actionsBilling,
} from "./billing";
import { DayViewAppo } from "@udok/lib/app/appointment";
import {
  validateAttachments,
  getAppointmentRequiredAttachments,
} from "@udok/lib/app/appointmentType";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type ScheduleListItem = Schedule & {
  locationName: string;
  clinicName: string;
};

type CalendarAppointmentInfo = {
  markedAt: string;
  patiID?: string;
  sescID: string;
  status: string;
};

export type CalendarFilter = {
  patiID?: string;
  specID?: string;
};

export type InitialState = {
  scheduleByID: { [sescID: string]: Schedule | undefined };
  appointmentByID: { [appoID: string]: Appointment | undefined };
  clinByID: { [clinID: string]: Clinic | undefined };
  documentByID: { [docuID: string]: DocumentData };
  docuIDByAppoID: { [appoID: string]: string[] };
  calendarFilter: CalendarFilter;
  collectDocumentByID: { [codoID: string]: FileAttachments };
  collectDocByAppoID: { [appoID: string]: string[] };
  collectDocByID: { [apcdID: string]: AppointmentCollectDocumentPresentation };
  appoRescheduleByID: { [appoID: string]: AppointmentReschedule };
  healthplanByID: { [heplID: string]: Healthplan };
  invoicesByAppoID: { [invoID: string]: string[] };
  appointmentReturnPending: {
    [doctID: string]: AppointmentReturnPending | undefined;
  };
  healthplanCardByPatiID: {
    [patiID: string]: PatientHealthplanCard[] | undefined;
  };
  appointmentCommunicationByID: {
    [appoID: string]: AppointmentCommunication | undefined;
  };
  displaySettingsByID: {
    [appoID: string]: AppointmentDisplaySettings | undefined;
  };
  appointmentFileByID: { [appoID: string]: string[] | undefined };
  appointmentSettingsByID: {
    [appoID: string]: AppointmentSettingsInfo | undefined;
  };
};

// Reducers
const initialState: InitialState = {
  scheduleByID: {},
  appointmentByID: {},
  clinByID: {},
  documentByID: {},
  docuIDByAppoID: {},
  calendarFilter: {},
  collectDocumentByID: {},
  collectDocByAppoID: {},
  collectDocByID: {},
  appoRescheduleByID: {},
  healthplanByID: {},
  invoicesByAppoID: {},
  appointmentReturnPending: {},
  healthplanCardByPatiID: {},
  appointmentCommunicationByID: {},
  displaySettingsByID: {},
  appointmentFileByID: {},
  appointmentSettingsByID: {},
};

class ScheduleSlice extends Hen<InitialState> {
  scheduleLoaded(v: Schedule) {
    this.state.scheduleByID[String(v.sescID)] = v;
  }
  schedulesLoaded(v: Schedule[]) {
    v.forEach((s) => {
      this.state.scheduleByID[String(s.sescID)] = s;
    });
  }
  scheduleRemoved(v: Schedule) {
    delete this.state.scheduleByID[String(v.sescID)];
  }
  appointmentLoaded(v: Appointment) {
    this.state.appointmentByID[String(v.appoID)] = v;
  }
  appointmentsLoaded(v: Appointment[]) {
    v.forEach((s) => {
      this.state.appointmentByID[String(s.appoID)] = s;
    });
  }
  loadClinic(clinic: Clinic) {
    this.state.clinByID[clinic.clinID] = clinic;
  }
  loadClinics(clinics: Clinic[]) {
    clinics.forEach((c: Clinic) => {
      if (!this.state.clinByID[c.clinID]) {
        this.state.clinByID[c.clinID] = c;
      }
    });
  }
  calendarFilterMerged(f: CalendarFilter) {
    this.state.calendarFilter = { ...this.state.calendarFilter, ...f };
  }
  listdocumentsLoaded(v: DocumentData[]) {
    v.forEach((s) => {
      this.state.documentByID[String(s.docuID)] = s;
    });
  }
  documentsLoaded(v: DocumentData[], appoID: string) {
    const appolist = this.state.docuIDByAppoID[appoID] ?? [];
    v.forEach((s) => {
      if (s) {
        const index = appolist.findIndex((ID) => ID === s.docuID);
        if (index === -1) {
          appolist.push(s.docuID);
        }
        this.state.documentByID[String(s.docuID)] = s;
      }
    });
    this.state.docuIDByAppoID[appoID] = appolist;
  }
  documentLoaded(doc: DocumentData, appoID: string) {
    const appolist = this.state.docuIDByAppoID[appoID] ?? [];
    const index = appolist.findIndex((ID) => ID === doc.docuID);
    if (index === -1) {
      appolist.push(doc.docuID);
    }
    this.state.documentByID[doc.docuID] = doc;
    this.state.docuIDByAppoID[appoID] = appolist;
  }
  oneDocumentLoaded(doc: DocumentData) {
    this.state.documentByID[doc.docuID] = doc;
  }
  newCollectDocument(
    appoID: string,
    a: AppointmentCollectDocument,
    f: FileData
  ) {
    const template = this.state.collectDocumentByID[a.codoID];
    const appolist = this.state.collectDocByAppoID[appoID] ?? [];

    const collectDocument: AppointmentCollectDocumentPresentation = {
      ...a,
      collectDocumentTitle: template?.title ?? "",
      collectDocumentDescription: template?.description ?? "",
      filleName: f.name,
      filleUrl: f.url,
    };

    const index = appolist.findIndex((ID) => ID === collectDocument.apcdID);
    if (index === -1) {
      appolist.push(collectDocument.apcdID);
    }
    this.state.collectDocByID[String(collectDocument.apcdID)] = collectDocument;
  }
  removecollectDocument(apcdID: string) {
    delete this.state.collectDocByID[apcdID];
  }
  colectDocumentLoaded(cd: FileAttachments) {
    this.state.collectDocumentByID[cd.codoID] = cd;
  }
  colectDocumentsLoaded(c: FileAttachments[]) {
    c.forEach((cd) => (this.state.collectDocumentByID[cd.codoID] = cd));
  }
  collectAppoDocumentsLoaded(
    v: AppointmentCollectDocumentPresentation[],
    appoID: string
  ) {
    const appolist = this.state.collectDocByAppoID[appoID] ?? [];
    v.forEach((s) => {
      if (s) {
        const index = appolist.findIndex((ID) => ID === s.apcdID);
        if (index === -1) {
          appolist.push(s.apcdID);
        }
        this.state.collectDocByID[String(s.apcdID)] = s;
      }
    });
    this.state.collectDocByAppoID[appoID] = appolist;
  }
  AppoRescheduleLoaded(r: AppointmentReschedule) {
    this.state.appoRescheduleByID[r.appoID] = r;
  }
  AppoReschedulesLoaded(res: AppointmentReschedule[]) {
    res.forEach((r) => {
      this.state.appoRescheduleByID[r.appoID] = r;
    });
  }
  healthplansLoaded(help: Healthplan[]) {
    help.forEach((h) => {
      this.state.healthplanByID[h.heplID] = h;
    });
  }
  invoicesLoaded(v: InvoiceView[], appoID: string) {
    this.state.invoicesByAppoID[appoID] = v.map((s) => {
      return s.invoID;
    });
  }
  appointmentStatusReloaded(appoID: string, d: AppointmentStatusData[]) {
    const orderedList = d.sort((a, b) =>
      moment(b.createdAt).diff(moment(a.createdAt))
    );
    const latestStatus = orderedList[0];
    if (this.state.appointmentByID[appoID]) {
      this.state.appointmentByID[appoID] = {
        ...(this.state.appointmentByID[appoID] as any),
        status: latestStatus.status,
        statusInfo: latestStatus.info,
        statusReason: latestStatus.reason,
      };
    }
  }
  pendingReturnLoaded(returns: AppointmentReturnPending[]) {
    returns.forEach((r) => {
      this.state.appointmentReturnPending[r.doctID] = r;
    });
  }
  healthplanCardLoaded(hpc: PatientHealthplanCard) {
    let cardList = this.state.healthplanCardByPatiID[hpc.patiID] ?? [];
    const index = cardList.findIndex((hc) => hc.heplID === hpc.heplID);
    if (index === -1) {
      cardList = [...cardList, hpc];
    } else {
      cardList[index] = hpc;
    }
    this.state.healthplanCardByPatiID[hpc.patiID] = cardList;
  }
  healthplanCardsLoaded(patiID: string, hpc: PatientHealthplanCard[]) {
    this.state.healthplanCardByPatiID[patiID] = hpc;
  }
  healthplanCardRemoved(patiID: string, phcaID: number) {
    this.state.healthplanCardByPatiID[patiID] =
      this.state.healthplanCardByPatiID[patiID]?.filter(
        (c) => c.phcaID !== phcaID
      );
  }
  appointmentCommunicationLoaded(ac: AppointmentCommunication) {
    this.state.appointmentCommunicationByID[ac.appoID] = ac;
  }
  appointmentDisplaySettingsLoaded(dp: AppointmentDisplaySettings) {
    this.state.displaySettingsByID[dp.appoID] = dp;
  }
  appointmentStatusChange(s: AppointmentStatusResponse) {
    this.state.appointmentByID[s.appoID] = {
      ...this.state.appointmentByID[s.appoID]!,
      status: s.status,
      statusInfo: s.info,
    };
  }
  loadAppointmentFiles(files: { appoID: string; fileID: string }[]) {
    const apfByID = this.state.appointmentFileByID;
    files.forEach((file) => {
      const list = [...(apfByID?.[file.appoID] ?? []), file.fileID];
      apfByID[file.appoID] = list.filter(
        (fileID, i, a) => a.indexOf(fileID) === i
      );
    });
    this.state.appointmentFileByID = apfByID;
  }
  appointmentSettingsLoaded(aps: AppointmentSettingsInfo) {
    this.state.appointmentSettingsByID[aps.appoID] = aps;
  }
}

export const [Reducer, actions] = hen(new ScheduleSlice(initialState), {
  [UNAUTHORIZED]: (state: InitialState) => {
    return {
      ...initialState,
      clinByID: state.clinByID,
      healthplanByID: state.healthplanByID,
    };
  },
});

// Selectors
const mainSelector = (state: RootState) => state.schedule;
const pendingReturnSelector = (state: RootState) =>
  state.schedule.appointmentReturnPending;
export const documentSelector = (state: RootState) =>
  state.schedule.documentByID;
export const collectDocumentSelector = (state: RootState) =>
  state.schedule.collectDocumentByID;
export const invoicesByAppoIDSelector = (state: RootState) =>
  state.schedule.invoicesByAppoID;
const appointmentSelector = (state: RootState) =>
  state.schedule.appointmentByID;
const clinicSelector = (state: RootState) => state.schedule.clinByID;
export const healthplanCardRepository = (state: RootState) =>
  mainSelector(state).healthplanCardByPatiID;
export const appointmentCommunicationRepository = (state: RootState) =>
  mainSelector(state).appointmentCommunicationByID;
export const displaySettingsRepository = (state: RootState) =>
  mainSelector(state).displaySettingsByID;
export const appoRescheduleRepository = (state: RootState) =>
  mainSelector(state).appoRescheduleByID;
export const scheduleRepository = (state: RootState) =>
  mainSelector(state).scheduleByID;
export const collectDocRepository = (state: RootState) =>
  mainSelector(state).collectDocByID;
export const collectAppoDocRepository = (state: RootState) =>
  mainSelector(state).collectDocByAppoID;
export const docuIDByAppoIDRepository = (state: RootState) =>
  mainSelector(state).docuIDByAppoID;
export const appointmentFileSelector = (state: RootState) =>
  mainSelector(state).appointmentFileByID;
export const appointmentSettingsByIDSelector = (state: RootState) =>
  mainSelector(state).appointmentSettingsByID;

export const oneAppointmentSettings = (props: { appoID?: string }) =>
  createSelector(
    [appointmentSettingsByIDSelector],
    (appointmentSettingsByID) => {
      return {
        settings: appointmentSettingsByID[props?.appoID ?? ""],
      };
    }
  );

export const getAppointmentFiles = (props: { appoID: string }) =>
  createSelector([appointmentFileSelector], (apfByID) => {
    return {
      files: apfByID?.[props.appoID] ?? [],
    };
  });

export const appointmentInvoicesView = (
  state: RootState,
  props: { appoID: string }
) =>
  createSelector(
    [invoicesByAppoIDSelector, invoicesByIDSelector],
    (invoicesByAppoID, invoicesByID) => {
      return {
        list:
          invoicesByAppoID[props.appoID]?.map?.(
            (invoID) => invoicesByID[invoID]
          ) ?? [],
      };
    }
  );

export const getAppointmentInvoices = createSelector(
  [invoicesByAppoIDSelector, invoicesByIDSelector],
  (invoicesByAppoID, invoicesByID) => {
    return {
      invoicesByAppoID,
      invoicesByID,
    };
  }
);

export const scheduleListView = createSelector(
  [mainSelector, locationByLocaID],
  (state, locationByID) => {
    return {
      list: Object.keys(state.scheduleByID)
        .map((s) => {
          const shedule = state.scheduleByID[s];
          let clinicName = shedule?.clinID
            ? state.clinByID[shedule?.clinID]?.name ?? ""
            : "n/a";

          let locationName = shedule?.locaID
            ? locationByID[shedule?.locaID]?.name ?? "Presencial"
            : "Consulta virtual";

          return {
            ...shedule,
            clinicName,
            locationName,
          } as ScheduleListItem;
        })
        .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))),
    };
  }
);
const calendarFilterSelector = (state: RootState) =>
  state.schedule.calendarFilter;

export const patientIDFilter = createSelector(
  [calendarFilterSelector],
  (state) => {
    return {
      value: state.patiID,
    };
  }
);
export const oneScheduleView = (state: RootState, props: { sescID: string }) =>
  createSelector([mainSelector, locationByLocaID], (state, locationByID) => {
    const schedule = state.scheduleByID[props.sescID];

    const clinic = schedule?.clinID
      ? state.clinByID[schedule?.clinID] ?? undefined
      : undefined;

    const location = schedule?.locaID
      ? locationByID[schedule.locaID] ?? undefined
      : undefined;

    return {
      schedule,
      clinic,
      location,
    };
  });
export const appointmentListView = createSelector([mainSelector], (state) => {
  const appointments = Object.keys(state.appointmentByID)
    .map((a) => state.appointmentByID[a] as Appointment)
    .sort((a, b) => moment(b.markedAt).diff(moment(a.markedAt)));

  return {
    list: appointments,
  };
});

export const appointmentCalendarView = (
  state: RootState,
  props: { daySelected: string }
) =>
  createSelector([mainSelector, calendarFilterSelector], (state, filter) => {
    const schedules = Object.keys(state.scheduleByID)
      .map((s) => state.scheduleByID[s] as Schedule)
      .filter((s) => (filter.patiID ? s.userID === filter.patiID : true));
    const date = moment(props.daySelected, "YYYY-MM");
    const appointmentsConfirmed = Object.keys(state.appointmentByID).map(
      (s) => state.appointmentByID[s] as Appointment
    );

    const cal = genCalendar(
      date.format("YYYY-MM-DD"),
      appointmentsConfirmed,
      schedules
    );
    return {
      appointments: cal,
    };
  });

export const getSuggestView = (
  state: RootState,
  props: { appo: Appointment }
) =>
  createSelector([mainSelector, locationByLocaID], (state, locationByID) => {
    const { appo } = props;

    const filterList = (item: Schedule) => {
      const clinID = appo?.clinID;
      const type = appo.type;
      if (item.type !== type) {
        return false;
      }
      if (clinID) {
        return item.clinID === clinID ? true : false;
      } else if (item.clinID) {
        return false;
      }

      return true;
    };

    const list = Object.keys(state.appointmentByID).map(
      (s) => state.appointmentByID[s] as Appointment
    );

    const schedules = Object.keys(state.scheduleByID)
      .map((s) => {
        const shedule = state.scheduleByID[s];
        let clinicInfo = shedule?.clinID
          ? state.clinByID[shedule?.clinID]
          : undefined;
        let localeInfo = shedule?.locaID
          ? locationByID[shedule?.locaID]
          : undefined;
        return {
          ...shedule,
          clinicInfo,
          localeInfo,
        } as Schedule;
      })
      .filter((i) => filterList(i));

    const appoMap = list.reduce((a: any, b) => {
      return {
        ...a,
        [b.sescID]: [...(a[b.sescID] ?? []), b],
      };
    }, {});

    const cal = genCalendar(moment().format("YYYY-MM-DD"), list, schedules);
    return {
      schedules,
      excludedHours: appoMap,
      calendar: cal,
    };
  });

export const appointmentDayView = (state: RootState, props: { day: string }) =>
  createSelector([mainSelector, locationByLocaID], (state, locationByID) => {
    const list = Object.keys(state.appointmentByID).map(
      (s) => state.appointmentByID[s] as Appointment
    );

    const schedules = Object.keys(state.scheduleByID).map((s) => {
      const shedule = state.scheduleByID[s];
      let clinicInfo = shedule?.clinID
        ? state.clinByID[shedule?.clinID]
        : undefined;
      let localeInfo = shedule?.locaID
        ? locationByID[shedule?.locaID]
        : undefined;
      return {
        ...shedule,
        clinicInfo,
        localeInfo,
      } as Schedule;
    });
    const appoMap = list.reduce((a: any, b) => {
      return {
        ...a,
        [b.sescID]: [...(a[b.sescID] ?? []), b],
      };
    }, {});

    const filteredList = list.filter((a) => {
      if (
        !a?.markedAt ||
        moment(a.markedAt).format("DD/MM/YYYY") !== props.day
      ) {
        return false;
      }
      if (a.appoID === null) {
        return false;
      }
      return true;
    });

    const values: DayViewAppo[] = filteredList.map((a) => {
      const schedule = a?.sescID ? state.scheduleByID[a.sescID] : undefined;
      const locaID = a?.locaID ?? "";
      const clinID = schedule?.clinID ?? "";
      const locationInfo = locaID
        ? (locationByID[locaID] as Location)
        : undefined;
      const clinicInfo = clinID
        ? (state.clinByID[clinID] as Clinic)
        : undefined;
      return {
        appointment: a,
        location: locationInfo,
        clinic: clinicInfo,
      } as DayViewAppo;
    });
    return {
      values: values,
      schedules: schedules,
      excludedHours: appoMap,
    };
  });

export const oneAppointmentView = (props: { appoID?: string }) =>
  createSelector([appointmentSelector], (appointmentByID) => {
    const { appoID } = props;
    const appointment = appoID ? appointmentByID[appoID] : undefined;

    return {
      appointment,
    };
  });

export const appointmentPaymentView = (props: { appoID: string }) =>
  createSelector(
    [mainSelector, invoicesByIDSelector],
    (state, invoicesByID) => {
      const { appointmentByID, scheduleByID } = state;
      const { appoID } = props;
      const appointment = appointmentByID[appoID];
      const schedule = appointment?.sescID
        ? (scheduleByID[appointment.sescID] as Schedule)
        : undefined;
      const invoices = (
        state.invoicesByAppoID[props.appoID]?.map?.(
          (invoID) => invoicesByID[invoID]
        ) ?? []
      ).filter((inv) => !!inv) as InvoiceView[];

      return {
        appointment,
        schedule,
        invoices,
      };
    }
  );

export const getAllAppoAttachments = (
  state: RootState,
  props: { appoID?: string }
) =>
  createSelector([mainSelector], (state) => {
    const { docuIDByAppoID, documentByID, collectDocByID, collectDocByAppoID } =
      state;
    const { appoID } = props;

    const listCollectDocu = appoID
      ? collectDocByAppoID?.[appoID]?.map((id) => collectDocByID[id]) ?? []
      : [];

    const listForms = appoID
      ? docuIDByAppoID?.[appoID]?.map((id) => documentByID[id]) ?? []
      : [];

    return {
      listCollectDocu,
      listForms,
    };
  });

export const createAttachmentsView = (
  state: RootState,
  props: { appoID?: string }
) =>
  createSelector(selectTemplateByID, mainSelector, (templateByID, state) => {
    const {
      docuIDByAppoID,
      documentByID,
      collectDocumentByID,
      collectDocByID,
      collectDocByAppoID,
      appointmentByID,
    } = state;
    const { appoID } = props;

    const appointment = appoID ? appointmentByID[appoID] : undefined;
    const { requiredDocuments, requiredForms } =
      getAppointmentRequiredAttachments(appointment);

    const documentsTemplates =
      requiredForms?.map?.((doteID) => templateByID[doteID] ?? {}) ?? [];
    const filledDocuments = appoID
      ? docuIDByAppoID?.[appoID]?.map?.(
          (docuID) => documentByID[docuID] ?? {}
        ) ?? []
      : [];

    const requiredAttachments = requiredDocuments.map(
      (codoID) => collectDocumentByID[codoID] ?? {}
    );

    const uploadedfiles = appoID
      ? collectDocByAppoID?.[appoID]?.map((id) => collectDocByID[id] ?? {}) ??
        []
      : [];

    const isValidAttachments = appointment
      ? validateAttachments(appointment, filledDocuments, uploadedfiles)
      : false;

    return {
      documentsTemplates,
      filledDocuments,
      uploadedfiles,
      requiredAttachments,
      isValidAttachments,
      doctID: appointment?.doctID,
      patiID: appointment?.patiID,
    };
  });

export const getAppointment = (state: RootState, props: { appoID?: string }) =>
  createSelector([mainSelector, getUserMe], (state, user) => {
    const appointment = props?.appoID
      ? state.appointmentByID[props.appoID]
      : undefined;
    const currentUser = user.currentUser;
    return {
      appointment,
      currentUser,
    };
  });

export const getViewAppointment = (
  state: RootState,
  props: { appoID: string }
) =>
  createSelector(
    [
      appointmentSelector,
      appoRescheduleRepository,
      invoicesByAppoIDSelector,
      invoicesByIDSelector,
      clinicSelector,
      healthplanCardRepository,
      collectDocRepository,
      collectAppoDocRepository,
      docuIDByAppoIDRepository,
      documentSelector,
      getUserMe,
    ],
    (
      appointmentByID,
      appoRescheduleByID,
      invoicesByAppoID,
      invoicesByID,
      clinByID,
      healthplanCardByPatiID,
      collectDocByID,
      collectDocByAppoID,
      docuIDByAppoID,
      documentByID,
      user
    ) => {
      const appointment = appointmentByID[props.appoID];
      const anticipated = appoRescheduleByID[props.appoID];
      const invoices =
        invoicesByAppoID[props.appoID]?.map?.(
          (invoID) => invoicesByID[invoID]
        ) ?? [];
      const clinic = clinByID[appointment?.clinID ?? ""];
      const healthplanCards =
        healthplanCardByPatiID[appointment?.patiID as string] ?? [];
      const listCollectDocu =
        collectDocByAppoID?.[appointment?.appoID as string]?.map(
          (id) => collectDocByID[id] ?? {}
        ) ?? [];
      const listForms =
        docuIDByAppoID?.[appointment?.appoID as string]?.map(
          (id) => documentByID[id] ?? {}
        ) ?? [];

      return {
        appointment,
        anticipated,
        invoices,
        clinic,
        healthplanCards,
        listCollectDocu,
        listForms,
        patient: user.currentUser?.patient,
      };
    }
  );

export const appointmentSuggestionView = (
  state: RootState,
  props: { appoID?: string }
) =>
  createSelector([mainSelector], (state) => {
    const appointment = props?.appoID
      ? state.appointmentByID[props.appoID]
      : undefined;
    const anticipated = props?.appoID
      ? state.appoRescheduleByID[props.appoID]
      : undefined;
    return {
      appointment,
      anticipated,
    };
  });

export const getComposerView = (state: RootState, props: { apscID?: string }) =>
  createSelector([mainSelector, getUserMe], (state, user) => {
    let anticipationData: AppointmentReschedule | undefined;
    if (props?.apscID) {
      anticipationData = Object.keys(state.appoRescheduleByID)
        .map((id) => state.appoRescheduleByID[id])
        .filter((a) => a.apscID === props.apscID)?.[0];
    }
    const currentUser = user.currentUser;
    return {
      anticipationData,
      currentUser,
    };
  });

export const healthplanListView = createSelector(mainSelector, (state) => {
  return {
    list: Object.keys(state.healthplanByID).map(
      (heplID) => state.healthplanByID[heplID]
    ),
  };
});

export const healthplanView = (state: RootState, props: { heplID: string }) =>
  createSelector(mainSelector, (state) => {
    return {
      healthplan: state.healthplanByID[props.heplID],
    };
  });

export const getClinicView = (state: RootState, props: { clinID?: string }) =>
  createSelector([clinicSelector], (clinByID) => {
    return {
      clinic: props?.clinID ? clinByID[props.clinID] : undefined,
    };
  });

export const appointmentPendingReturn = (
  state: RootState,
  props: { doctID: string }
) =>
  createSelector([pendingReturnSelector], (pendingReturn) => {
    const appointmentPendingReturn = pendingReturn[props.doctID];
    return {
      appointmentPendingReturn,
    };
  });

export const getFisrtAppointmentOfTheDay = createSelector(
  [appointmentSelector],
  (appointmentByID) => {
    const finishedStatus: string[] = [
      AppointmentStatus.cancel,
      AppointmentStatus.canceledForReschedule,
      AppointmentStatus.rescheduled,
      AppointmentStatus.done,
    ];
    const currentDay = moment();
    const FisrtAppo = Object.keys(appointmentByID)
      .map((appoID) => appointmentByID[appoID])
      .filter(
        (app) =>
          moment(app?.markedAt).isSame(currentDay, "day") &&
          finishedStatus.indexOf(app?.status ?? "") === -1
      )
      .sort((a, b) => moment(a?.markedAt).diff(moment(b?.markedAt)));

    return {
      appointment: FisrtAppo[0],
    };
  }
);

export const getPatientHealthplanCards = (
  state: RootState,
  props: { patiID: string }
) =>
  createSelector([healthplanCardRepository], (healthplanCardByPatiID) => {
    return {
      healthplanCards: healthplanCardByPatiID[props.patiID] ?? [],
    };
  });

export const getOneAppointmentCommunication = (props: { appoID: string }) =>
  createSelector([appointmentCommunicationRepository], (communicationByID) => {
    return {
      communication: communicationByID[props.appoID],
    };
  });

export const getOneAppointmentDisplaySettings = (props: { appoID: string }) =>
  createSelector([displaySettingsRepository], (displaySettingsByID) => {
    return {
      displaySettings: displaySettingsByID[props.appoID],
    };
  });

// Actions
export function loadAllSchedules(f?: ScheduleFilter): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchSchedules(apiToken, f)
      .then(async (r) => {
        dispatch(actions.schedulesLoaded(r));
        const clinics = await Promise.all(
          r.map((s) => (s.clinID ? fetchClinicProfileByClinID(s.clinID) : null))
        );
        dispatch(
          actions.loadClinics(clinics.filter((c) => c != null) as Clinic[])
        );
        const locations = await Promise.all(
          r.map((s) => (s.locaID ? fetchLocation(apiToken, s.locaID) : null))
        );
        dispatch(
          locationActions.locationsLoaded(
            locations.filter((l) => l != null) as Location[]
          )
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadSchedulesPresentation(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    return dispatch(loadAllSchedules());
  };
}

export function loadOneSchedule(sescID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchSchedule(apiToken, sescID)
      .then(async (r) => {
        dispatch(actions.scheduleLoaded(r));
        if (r?.clinID) {
          const clinic = await fetchClinicProfileByClinID(r.clinID);
          dispatch(actions.loadClinic(clinic));
        }
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadOneClinic(clinID: string): AppThunk<Promise<void>> {
  return async (dispatch) => {
    return fetchClinicProfileByClinID(clinID)
      .then((clinic) => {
        dispatch(actions.loadClinic(clinic));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadClinicByURL(url: string): AppThunk<Promise<Clinic>> {
  return async (dispatch) => {
    return fetchClinicProfile(url)
      .then((clinic) => {
        dispatch(actions.loadClinic(clinic));
        return clinic;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedClinicByURL(url: string): AppThunk<Promise<Clinic>> {
  return async (dispatch, getState) => {
    const state = getState();
    const clinID = Object.keys(state.schedule.clinByID).find((id) => {
      const c = state.schedule.clinByID[id];
      if (c?.url === url) {
        return true;
      }
      return false;
    });

    if (!!clinID) {
      return Promise.resolve(state.schedule.clinByID[clinID]!);
    }
    return dispatch(loadClinicByURL(url));
  };
}

export function fetchCachedClinic(clinID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const clinicExist = Boolean(state.schedule.clinByID[clinID]);
    if (clinicExist) {
      return Promise.resolve();
    }
    return dispatch(loadOneClinic(clinID));
  };
}

export function createOneSchedule(schedule: Schedule): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createSchedule(apiToken, schedule)
      .then((r) => {
        dispatch(actions.scheduleLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function updateOneSchedule(schedule: Schedule): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateSchedule(apiToken, schedule)
      .then((r) => {
        dispatch(actions.scheduleLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeOneSchedule(sescID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return deleteSchedule(apiToken, sescID)
      .then((r) => {
        dispatch(actions.scheduleRemoved(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Realizado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadAppointments(
  f: FilterAppointment
): AppThunk<Promise<Appointment[]>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchAppointments(apiToken, f)
      .then((r) => {
        dispatch(actions.appointmentsLoaded(r));
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadAppointment(appoID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchAppointment(apiToken, appoID)
      .then(async (r) => {
        dispatch(actions.appointmentLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchCachedAppointment(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const appointmentExist = Boolean(state.schedule.appointmentByID[appoID]);
    if (appointmentExist) {
      return Promise.resolve();
    }
    return dispatch(loadAppointment(appoID));
  };
}

export function editStatusAppointment(
  appoID: string,
  status: AppointmentStatus,
  reasonDescription?: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    if (
      status === AppointmentStatus.cancel ||
      status === AppointmentStatus.canceledForReschedule
    ) {
      return Promise.reject();
    }
    return sendEditStatusAppointment(apiToken, appoID, {
      status,
      reasonDescription,
    })
      .then((r) => {
        dispatch(actions.appointmentStatusChange(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Status alterado com sucesso!",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e,
          })
        );
        throw e;
      });
  };
}

export function appointmentCancelAndRefund(
  appoID: string,
  status: {
    status: AppointmentStatus.cancel | AppointmentStatus.canceledForReschedule;
  } & Omit<NewAppointmentStatus, "status">,
  paymID?: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return sendEditStatusAppointment(apiToken, appoID, status)
      .then(async (s) => {
        const resp = {
          ...s,
          nameCreatedBy: state.user?.myProfile?.doctor?.name,
        };
        dispatch(actions.appointmentStatusChange(resp));
        if (paymID) {
          await dispatch(refundInvoicePayment(paymID));
        }
        const msgByStatus =
          s.status === AppointmentStatus.canceledForReschedule
            ? "Sugestão enviada"
            : "Agendamento cancelado";
        dispatch(
          newNotification("general", {
            status: "success",
            message: msgByStatus,
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

function genCalendar(
  monthDate: string,
  appos: Array<CalendarAppointmentInfo>,
  scheds: Array<Schedule>
) {
  const event2: any = [];
  if (scheds.length > 0) {
    scheds.forEach((element) => {
      if ((element.weekDay?.length ?? 0) > 0) {
        element.weekDay.forEach((wd) => {
          var dayName = moment([
            moment(monthDate).year(),
            moment(monthDate).month(),
            1,
          ])
            .startOf("month")
            .day(DaysOfWeek.indexOf(wd));

          if (dayName.date() > 7) dayName.add(7, "d");
          var month = moment(monthDate).month();

          while (month === dayName.month()) {
            var x = moment([
              moment(dayName).year(),
              moment(dayName).month(),
              moment(dayName).date(),
              moment(element.startAt).hour(),
              moment(element.startAt).minute(),
            ]);
            var z = moment([
              moment(dayName).year(),
              moment(dayName).month(),
              moment(dayName).date(),
              moment(element.endAt).hour(),
              moment(element.endAt).minute(),
            ]);

            var a = {
              ...element,
              startAt: x,
              endAt: z,
            };
            event2.push(a);
            dayName.add(7, "d");
          }
        });
      } else {
        event2.push(element);
      }
    });
  }

  let appoDayMap: { [k: string]: CalendarAppointmentInfo[] } = {};

  appos.forEach((a) => {
    appoDayMap[moment(a.markedAt).format("YYYY-MM-DD")] = [
      ...(appoDayMap[moment(a.markedAt).format("YYYY-MM-DD")] || []),
      a,
    ];
  });
  let schedDateMap: any = {};
  event2.forEach((r: any) => {
    const d = moment(r.startAt).format("YYYY-MM-DD");
    const appos = (appoDayMap[d] ?? [])
      .filter((item: any) => item?.status !== "canceled")
      .filter((a) => a.sescID === r.sescID);
    const c = schedDateMap[d];
    const slots = Math.floor(
      Math.abs(moment(r.endAt).diff(moment(r.startAt), "minutes")) /
        r.appointmentDuration
    );
    const data = {
      slots: (c?.data?.slots ?? 0) + slots,
      occupied: (c?.data?.occupied ?? 0) + appos.length,
    };
    schedDateMap[d] = {
      title: `Vagas: ${data.slots} \n Agendamentos: ${data.occupied}`,
      allDay: true,
      start: moment(r.startAt).format("YYYY-MM-DD HH:mm:ss"),
      end: moment(r.endAt).format("YYYY-MM-DD HH:mm:ss"),
      data,
    };
  });
  return Object.keys(schedDateMap).map((k) => schedDateMap[k]);
}

export function copyNotification(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    dispatch(
      newNotification("general", {
        status: "success",
        message: "Link copiado com sucesso.",
      })
    );
  };
}

export function updateReturnConsultations(
  obj: {
    markedAt?: string;
    returnVisitEnabled?: boolean;
    notificationOptions?: AppointmentNotificationOptions;
  },
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    let newAppo = state.schedule.appointmentByID[appoID];
    if (newAppo) {
      newAppo = {
        ...newAppo,
        returnVisitEnabled: obj.returnVisitEnabled,
      };
      dispatch(actions.appointmentLoaded(newAppo));
    }
    return sendEditAppointment(apiToken, appoID, obj)
      .then((r) => {
        dispatch(actions.appointmentLoaded(r));
      })
      .catch((e) => {
        if (newAppo) {
          newAppo = {
            ...newAppo,
            returnVisitEnabled: !obj.returnVisitEnabled,
          };
          dispatch(actions.appointmentLoaded(newAppo));
        }
        dispatch(
          newNotification("general", {
            status: "error",
            message: (e.response?.data?.error || e).message,
          })
        );
        throw e;
      });
  };
}

export function loadAttachmentsByAppoID(
  appoID: string,
  f?: FilterAttachment
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchAttachmentsByappoID(apiToken, appoID, f)
      .then(async (r) => {
        const atchs = r;
        const documents = await Promise.all(
          atchs
            .filter((att) => att.resource === ResourceType.document)
            .map((a) => {
              return fetchDocument(apiToken, a.resourceID);
            })
        );
        dispatch(actions.documentsLoaded(documents, appoID));

        const collectDocuments = await Promise.all(
          atchs
            .filter((att) => att.resource === ResourceType.collectDocument)
            .map((a) => {
              return fetchAppoCollectDocuments(apiToken, a.resourceID);
            })
        );
        dispatch(actions.collectAppoDocumentsLoaded(collectDocuments, appoID));

        dispatch(actions.collectAppoDocumentsLoaded(collectDocuments, appoID));
        const appointmentFiles = atchs
          .filter((att) => att.resource === ResourceType.file)
          .map((a) => ({ appoID: a.appoID, fileID: a.resourceID }));
        dispatch(actions.loadAppointmentFiles(appointmentFiles));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadInvoicesByAppoID(appoID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchInvoicesByAppoID(apiToken, appoID)
      .then(async (r) => {
        dispatch(actions.invoicesLoaded(r, appoID));
        dispatch(actionsBilling.invoicesLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function createForm(
  formData: FormData
): AppThunk<Promise<void | DocumentData>> {
  return async (dispatch, getState) => {
    const apiToken = "Bearer " + getState().auth.token.raw;
    return createFormData(apiToken, formData)
      .then((r) => {
        dispatch(actions.oneDocumentLoaded(r));
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Histórico atualizado com sucesso",
          })
        );
        return r;
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: (e?.response?.data?.error || e)?.message,
          })
        );
      });
  };
}

export function createAppointmentDocument(
  n: NewDocumentForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createAppointmentAttachment(n, apiToken)
      .then(async (r) => {
        const Document = await fetchDocument(apiToken, r.resourceID);
        dispatch(actions.documentLoaded(Document, r.appoID));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function createFiles(
  file: File,
  appoID: string,
  codoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return uploadFile(file, apiToken)
      .then(async (r) => {
        const collect = {
          fileID: r.fileID,
          codoID,
          appoID,
        } as NewCollectDocumentForm;
        await createCollectDocument(apiToken, collect).then(async (r) => {
          await fetchFileMetadata(apiToken, r.fileID).then((f) => {
            dispatch(actions.newCollectDocument(appoID, r, f));
          });
        });
      })
      .catch((e) => {
        console.warn(e);
      });
  };
}

export function deleteOneCollectDocument(
  fileID: string,
  apcdID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    deleteCollectDocument(apiToken, apcdID)
      .then(() => {
        deleteFile(apiToken, fileID);
        dispatch(actions.removecollectDocument(apcdID));
      })
      .catch((e) => {
        console.warn(e);
      });
  };
}

export function receiveFile(
  fileID: string,
  name: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchFile(apiToken, fileID).then((r) => {
      fileDownload(r as any, name);
    });
  };
}

export function fetchOneCollectDocuments(
  codoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchCollectDocument(apiToken, codoID)
      .then((r) => {
        dispatch(actions.colectDocumentLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchRequiredCollectDocuments(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const t = getToken(state);
      const apiToken = "Bearer " + t.token.raw;
      let appointment = state.schedule.appointmentByID[appoID];
      if (!appointment) {
        await fetchAppointment(apiToken, appoID).then((a) => {
          appointment = a;
          dispatch(actions.appointmentLoaded(a));
        });
      }
      const documentsNotFound = (appointment?.requiredDocuments ?? []).filter(
        (codoID) => !Boolean(state.schedule.collectDocumentByID[codoID])
      );
      if (documentsNotFound.length > 0) {
        const collectDocuments = await Promise.all(
          documentsNotFound.map((codoID) =>
            fetchCollectDocument(apiToken, codoID)
          )
        );
        dispatch(actions.colectDocumentsLoaded(collectDocuments));
      }
    } catch (e) {
      dispatch(
        newNotification("general", {
          status: "error",
          message: (e as any).message,
        })
      );
      throw e;
    }
  };
}

export function fetchRequiredAppointmentTemplates(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const t = getToken(state);
      const apiToken = "Bearer " + t.token.raw;
      let appointment = state.schedule.appointmentByID[appoID];
      if (!appointment) {
        await fetchAppointment(apiToken, appoID).then((a) => {
          appointment = a;
          dispatch(actions.appointmentLoaded(a));
        });
      }
      const formsNotFound = (appointment?.requiredForms ?? []).filter(
        (doteID) => !Boolean(state.documents.templateByID[doteID])
      );
      if (formsNotFound.length > 0) {
        const templates = await Promise.all(
          formsNotFound.map((doteID) => fetchDocumentTemplate(apiToken, doteID))
        );
        dispatch(docAction.templatesLoaded(templates));
      }
    } catch (e) {
      dispatch(
        newNotification("general", {
          status: "error",
          message: (e as any).message,
        })
      );
      throw e;
    }
  };
}

export function updateOneAppointmentReschedule(
  apscID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateAppointmentReschedule(apiToken, apscID)
      .then(async (r) => {
        dispatch(actions.AppoRescheduleLoaded(r));
        let appointment = state.schedule.appointmentByID[r.appoID];
        if (appointment) {
          appointment = {
            ...appointment,
            markedAt: r.markedAt,
          };
          dispatch(actions.appointmentLoaded(appointment));
        } else {
          await fetchAppointment(apiToken, r.appoID).then((a) => {
            appointment = a;
            dispatch(actions.appointmentLoaded(a));
          });
        }
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Novo horário aceito.",
          }) as any
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function createOneAppointment(
  newAppo: NewAppointment,
  notifyDoctor?: boolean
): AppThunk<Promise<Appointment>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createAppointment(apiToken, newAppo)
      .then((r) => {
        dispatch(actions.appointmentLoaded(r));
        emitAppointmentCreated(r.appoID);
        if (r?.appoID && r?.clinID) {
          const disableWhatsapp = r?.notificationOptions?.disableWhatsapp;
          if (!disableWhatsapp) {
            dispatch(
              sendControlledAppoPhone({
                appoID: r.appoID,
                sendTarget: "patient",
              })
            );
          }
          if (notifyDoctor) {
            setTimeout(() => {
              dispatch(
                sendControlledAppoPhone({
                  appoID: r.appoID,
                  sendTarget: "doctor",
                })
              );
            }, 2000);
          }
        }
        return Promise.resolve(r);
      })
      .catch((e) => {
        let section = "general";
        let message = e.message;
        let temporary = true;
        if (e.message.indexOf("schedule unavailable") > -1) {
          section = "onboarding";
          temporary = false;
          message = "Este horário foi bloqueado, selecione outra data";
        }
        if (e.message.indexOf("invalid health plan") > -1) {
          section = "onboarding";
          temporary = false;
          message = "Plano de saúde inválido!";
        }

        dispatch(
          newNotification(section, {
            status: "error",
            message,
            temporary,
          })
        );
        return Promise.reject(e);
      });
  };
}

export function createNewImmediateCareAppointment(
  newAppo: ImmediateCareAppointmentForm
): AppThunk<Promise<Appointment>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createImmediateCareAppointment(apiToken, newAppo)
      .then((r) => {
        dispatch(actions.appointmentLoaded(r));
        emitAppointmentCreated(r.appoID);
        return Promise.resolve(r);
      })
      .catch((e) => {
        let section = "general";
        let message = e.message;
        let temporary = true;
        if (e.message.indexOf("duplicate appointment") > -1) {
          section = "onboarding";
          temporary = false;
          message = "Você já está na fila de atendimento.";
        }
        if (e.message.indexOf("schedule unavailable") > -1) {
          section = "onboarding";
          temporary = false;
          message = "Este horário foi bloqueado, selecione outra data";
        }
        if (e.message.indexOf("invalid health plan") > -1) {
          section = "onboarding";
          temporary = false;
          message = "Plano de saúde inválido!";
        }

        dispatch(
          newNotification(section, {
            status: "error",
            message,
            temporary,
          })
        );
        return Promise.reject(e);
      });
  };
}

export function loadOneAppointmentReschedule(
  apscID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return fetchAppointmentReschedule(apiToken, apscID)
      .then((r) => {
        dispatch(actions.AppoRescheduleLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function loadAppointmentReschedules(
  f?: FilterAppointmentReschedule
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchAppointmentReschedules(apiToken, f)
      .then((r) => {
        dispatch(actions.AppoReschedulesLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function fetchCachedSchedule(sescID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const locationExist = Boolean(state.schedule.scheduleByID[sescID]);
    if (locationExist) {
      return Promise.resolve();
    }
    return dispatch(loadOneSchedule(sescID));
  };
}

export function fetchScheduleLocation(sescID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    try {
      let schedule = state.schedule.scheduleByID[sescID];
      if (!schedule) {
        await fetchSchedule(apiToken, sescID).then((r) => {
          schedule = r;
          dispatch(actions.scheduleLoaded(r));
        });
      }
      if (schedule?.locaID) {
        await fetchLocation(apiToken, schedule.locaID).then((r) => {
          dispatch(locationActions.locaLoaded(r));
        });
      }
    } catch (e) {
      dispatch(
        newNotification("general", {
          status: "error",
          message: (e as any).message,
        })
      );
      throw e;
    }
  };
}

export function createAppointmentReschedule(
  consultation: NewAppointment,
  appoID: string,
  currentConsultationinfo: any
): AppThunk<Promise<{ appoID: string }>> {
  return async (dispatch, getState) => {
    const apiToken = "Bearer " + getState().auth.token.raw;
    return createAppointment(apiToken, consultation)
      .then(async (appointment) => {
        dispatch(actions.appointmentLoaded(appointment));
        return sendEditStatusAppointment(apiToken, appoID, {
          status: AppointmentStatus.rescheduled,
          info: {
            ...currentConsultationinfo,
            appoID: appointment.appoID,
          },
        }).then(() => {
          dispatch(
            newNotification("general", {
              status: "success",
              message: "Agendamento criado com sucesso",
            }) as any
          );
          return {
            appoID: appointment.appoID,
          };
        });
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message ?? e?.response?.data?.error,
          })
        );
        throw e;
      });
  };
}

export function loadHealthplans(f?: FilterHealthPlan): AppThunk<Promise<void>> {
  return async (dispatch) => {
    return fetchHealthplans(f)
      .then((r) => {
        dispatch(actions.healthplansLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}
export function reloadAppoinmentStatus(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const apiToken = "Bearer " + getState().auth.token.raw;
    return fetchAppointmentStatus(apiToken, appoID)
      .then((r) => {
        dispatch(actions.appointmentStatusReloaded(appoID, r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadPendingReturns(doctID?: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const apiToken = "Bearer " + getState().auth.token.raw;
    return fetchAppointsReturnPending(apiToken, { doctID })
      .then((r) => {
        dispatch(actions.pendingReturnLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function sendControlledAppoPhone(mail: {
  appoID: string;
  sendTarget: "patient" | "doctor";
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return sendControlledAppointmentPhone(apiToken, mail)
      .then((r) => {})
      .catch((e) => {
        console.warn(e);
      });
  };
}

export function createOneHealthplanCard(
  data: Partial<PatientHealthplanCard>
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    const patiID = t.token.payload?.patiID;
    data.patiID = patiID;
    return createHealthplanCard(apiToken, data)
      .then((r) => {
        dispatch(actions.healthplanCardLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadHealthplanCards(
  patiID: string,
  filter?: FilterPatientHealthplanCard
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchHealthplanCards(apiToken, patiID, filter)
      .then((r) => {
        dispatch(actions.healthplanCardsLoaded(patiID, r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function fetchMyHealthplanCards(
  filter?: FilterPatientHealthplanCard
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const patiID = state.user.myProfile?.patient?.patiID;
    if (!patiID) {
      return Promise.resolve();
    }
    return dispatch(loadHealthplanCards(patiID, filter));
  };
}

export function updateOneHealthplanCard(
  data: Partial<PatientHealthplanCard>
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return updateHealthplanCard(apiToken, data)
      .then((r) => {
        dispatch(actions.healthplanCardLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function removeOneHealthplanCard(
  phcaID: number
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    const patiID = t.token.payload?.patiID;
    return deleteHealthplanCard(apiToken, patiID!, phcaID)
      .then((r) => {
        dispatch(actions.healthplanCardLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function getAppointmentCommunication(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchAppointmentCommunication(apiToken, appoID)
      .then((r) => {
        dispatch(actions.appointmentCommunicationLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function fetchCachedAppointmentCommunication(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const communicationExist = Boolean(
      state.schedule.appointmentCommunicationByID[appoID]
    );
    if (communicationExist) {
      return Promise.resolve();
    }
    return dispatch(getAppointmentCommunication(appoID));
  };
}

export function loadAppointmentDisplaySettings(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    return getAppointmentDisplaySettings(appoID)
      .then((r) => {
        dispatch(actions.appointmentDisplaySettingsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function fetchCachedAppointmentDisplaySettings(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const displayPreferencesExist = Boolean(
      state.schedule.displaySettingsByID[appoID]
    );
    if (displayPreferencesExist) {
      return Promise.resolve();
    }
    return dispatch(loadAppointmentDisplaySettings(appoID));
  };
}

export function loadAppointmentSettings(
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return getAppointmentSettings(apiToken, appoID)
      .then((r) => {
        dispatch(actions.appointmentSettingsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}
