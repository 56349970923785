import React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "ducks/state";
import { loginOTP, unauthorized } from "ducks/auth";
import { useHistory, useLocation } from "react-router-dom";
import { UdokDomains } from "@udok/lib/internal/constants";

function getDomainURL(option: string) {
  return (
    (
      {
        [UdokDomains.WebUdok]: process.env.REACT_APP_WEB_APPLICATION_URL,
      } as { [k: string]: string }
    )?.[option] ?? ""
  );
}

const Redirect = ({ domain, path }: { domain: string; path: string }) => {
  const history = useHistory();

  const redirectToUrl = React.useCallback(() => {
    const domainURL = getDomainURL(domain ?? "");
    if (!domainURL) {
      history.replace("/");
      return;
    }
    window.location.href = `${domainURL}${path}`;
  }, [history, domain, path]);

  React.useEffect(() => {
    redirectToUrl();
  }, [redirectToUrl]);

  return <div />;
};

export const OTPRedirect = () => {
  const dispatch: AppDispatch = useDispatch();
  const { search } = useLocation();
  const [loading, setLoading] = React.useState(true);
  const query = new URLSearchParams(search);
  const otp = query.get("otp");
  const path = query.get("path") ?? "";
  const domain = query.get("domain") ?? "";

  React.useEffect(() => {
    if (!otp) {
      setLoading(false);
      return;
    }
    dispatch(unauthorized());
    dispatch(loginOTP(otp)).finally(() => setLoading(false));
  }, [dispatch, otp]);

  return (
    <div>{loading ? <div /> : <Redirect path={path} domain={domain} />}</div>
  );
};
