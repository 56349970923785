import { RootState, AppThunk } from "ducks/state";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import { DoctorReviewForm } from "@udok/lib/api/models";
import { createDoctorReview } from "@udok/lib/api/doctorReview";
import { getToken } from "./auth";

export type InitialState = {};

// Reducers
const initialState: InitialState = {};

class Doctors extends Hen<InitialState> {}

export const [Reducer, actions] = hen(new Doctors(initialState));

// Selectors
export const mainSelector = (state: RootState) => state.doctorReview;

// Actions
export function createOneDoctorReview(
  form: DoctorReviewForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return createDoctorReview(apiToken, form)
      .then(() => {
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Avaliação enviada com sucesso!",
          }) as any
        );
      })
      .catch((e) => {
        console.warn(e);
        dispatch(
          newNotification("general", {
            status: "error",
            message: "Falha ao enviar avaliação",
          })
        );
        throw e;
      });
  };
}
